<template>
  <div class="awardBox">
    <NavBar title="奖励" :left-arrow="false">
      <!-- <template #right>
        <TextBox :font-size="26" @click.native="rule">规则</TextBox>
      </template> -->
    </NavBar>
    <div class="canvasBg">
      <Space :height="30"></Space>
      <van-tabs type="card" v-model="tabActive">
        <van-tab title="一期"></van-tab>
        <van-tab title="二期"></van-tab>
      </van-tabs>
      <Space :height="109"></Space>
      <div class="canvasBox">
        <div class="one" @click="clickCanvas(3)">
          <div class="tipsBox" :class="{ active: canvasActive === 3 }">
            <img src="@/assets/images/award/canvas1.png" alt="" class="icon" />
            <span>奖励{{ originListObj[3] && originListObj[3].poolRate }}%</span>
          </div>
        </div>
        <div class="two" @click="clickCanvas(2)">
          <div class="tipsBox" :class="{ active: canvasActive === 2 }">
            <img src="@/assets/images/award/canvas6.png" alt="" class="icon" />
            <span>奖励{{ originListObj[2] && originListObj[2].poolRate }}%</span>
          </div>
        </div>
        <div class="three" @click="clickCanvas(1)">
          <div class="tipsBox" :class="{ active: canvasActive === 1 }">
            <img src="@/assets/images/award/canvas5.png" alt="" class="icon" />
            <span>奖励{{ originListObj[1] && originListObj[1].poolRate }}%</span>
          </div>
        </div>
      </div>
    </div>
    <Space :height="30"></Space>
    <van-tabs type="card" v-model="grade">
      <van-tab title="v1"></van-tab>
      <van-tab title="v2"></van-tab>
      <van-tab title="v3"></van-tab>
    </van-tabs>
    <div class="contentBox">
      <Space :height="30"></Space>
      <template v-for="(item, index) of listTarget">
        <van-row gutter="20" :key="index">
          <van-col span="12" v-for="(inner, innerIndex) of item" :key="innerIndex">
            <div class="box" :class="colorClass">
              <img :src="inner.img" alt="" class="icon" />
              <Space :height="41"></Space>
              <PriceUnit
                :value="inner.value"
                :unit="inner.unit"
                :price="inner.price"
                :prifix="inner.prifix"
              ></PriceUnit>
              <Space :height="33"></Space>
              <TextBox :font-size="22" color="intro">{{ inner.title }}</TextBox>
            </div>
          </van-col>
        </van-row>
        <Space :height="30" :key="`Space${index}`"></Space>
      </template>
      <!-- <div class="introBox radius10" :class="colorClass">
        <TextBox :font-size="24" color="title">
          V{{ level }}达标条件：自购{{ originListObj[level] && originListObj[level].selfPower
          }}{{ tabActive === 0 ? '台' : 'T' }}存储服务器，累计推广业绩{{
            originListObj[level] && originListObj[level].power
          }}{{ tabActive === 0 ? '台' : 'T' }}存储服务器。
        </TextBox>
      </div>
      <Space :height="30"></Space> -->
    </div>
    <TabBar :active="1"></TabBar>
  </div>
</template>
<script>
export default {
  name: '',
  // mixins: [],
  // components: {},
  // props: [],
  data() {
    return {
      tabActive: 0,
      originListObj: [],
      canvasActive: 3,
      list1: [],
      list2: [],
      list3: [],
      grade: 0,
    };
  },
  created() {
    this.getDetail();
  },
  mounted() {
    // this.listTarget = this.list1;
  },
  methods: {
    getDetail() {
      this.$http('get', '/v1/netdata/reward/info', {
        goodsId: this.tabActive + 1,
      }).then(res => {
        res.data.forEach(item => {
          this.originListObj[item.level] = item;
          let pngProcess = res => {
            switch (res) {
              case 1:
                return 'a';
              case 2:
                return 'b';
              case 3:
                return 'c';
            }
          };
          this[`list${item.level}`] = [
            [
              {
                img: require(`@/assets/images/award/${pngProcess(item.level)}1.png`),
                prifix: '',
                value: item.todayCoin,
                price: true,
                unit: 'FIL',
                title: '今日新增',
              },
              {
                img: require(`@/assets/images/award/${pngProcess(item.level)}2.png`),
                prifix: '',
                value: item.allCoin,
                price: true,
                unit: 'FIL',
                title: '奖励累计',
              },
            ],
            [
              {
                img: require(`@/assets/images/award/${pngProcess(item.level)}3.png`),
                prifix: '',
                value: item.targetNum,
                price: false,
                unit: '人',
                title: '达标人数',
              },
              {
                img: require(`@/assets/images/award/${pngProcess(item.level)}4.png`),
                prifix: '$ ',
                value: item.filPrice,
                price: true,
                unit: '',
                title: 'FIL当前价',
              },
            ],
          ];
        });
      });
    },
    rule() {
      this.$router.push('/award/rule');
    },
    clickCanvas(res) {
      this.canvasActive = res;
    },
  },
  watch: {
    tabActive(res) {
      this.grade = 0;
      this.getDetail();
    },
  },
  computed: {
    level() {
      return this.grade + 1;
    },
    listTarget() {
      return this[`list${this.level}`];
    },
    colorClass() {
      return `v${this.level}Color`;
    },
  },
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less">
.canvasBg {
  background: linear-gradient(180deg, #ffffff 0%, #f1f1f9 100%);
}
.canvasBox {
  height: 576px;
  background: url('~@/assets/images/award/canvas4.png') no-repeat center;
  background-size: contain;
  position: relative;
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #ffffff 0%, #f1f1f9 100%);
    position: relative;
    z-index: -1;
  }
  .tipsBox {
    width: 230px;
    height: 55px;
    position: relative;
    left: 40%;
    background: url('~@/assets/images/award/canvas2.png') no-repeat left 8px bottom 8px;
    background-size: contain;
    display: flex;
    justify-content: flex-start;
    .icon {
      width: 60px;
      height: 28px;
      margin-left: 40px;
    }
    span {
      font-size: 24px;
      color: @intro-color;
      margin-left: 4px;
    }
    &.active {
      background: url('~@/assets/images/award/canvas3.png') no-repeat left bottom;
      background-size: contain;
      span {
        color: @title-color;
      }
    }
  }
  .one {
    position: absolute;
    left: 270px;
    top: 20px;
    width: 190px;
    height: 250px;
  }
  .two {
    position: absolute;
    left: 406px;
    bottom: 40px;
    width: 190px;
    height: 330px;
  }
  .three {
    position: absolute;
    left: 160px;
    bottom: 30px;
    width: 190px;
    height: 230px;
    .tipsBox {
      transform: rotateY(180deg);
      transform-origin: 0%;
      left: 65%;
      .icon {
        transform: rotateY(180deg);
      }
      span {
        transform: rotateY(180deg);
      }
    }
  }
}
.box {
  width: 335px;
  height: 292px;
  .radius;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 62px;
    height: 62px;
  }
}
.introBox {
  padding: 15px 22px;
}
.v1Color {
  background: #f6f8fe;
}
.v2Color {
  background: #ebf0fb;
}
.v3Color {
  background: #f8eee0;
}
</style>
